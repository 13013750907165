import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { themeVal, glsp, media } from '@devseed-ui/theme-provider';
import { Button } from '@devseed-ui/button';

import UniversalGridder from '../../styles/universal-gridder';
import { stripe } from '../../styles/motifs';

import Layout from '../../components/layout';
import {
  Inpage,
  InpageHeader,
  InpageHeaderInner,
  InpageHeadline,
  InpageTitle,
  InpageLead,
  InpageBody
} from '../../styles/inpage';
import {
  Fold,
  FoldInner,
  FoldHeader,
  FoldSuptitle,
  FoldBody,
  FoldBlock,
  FoldBlockTitle
} from '../../styles/fold';
import { FoldMosaicFeatures } from '../../components/mosaic';
import Media from '../../components/media';

import MediaCollageItem01 from '../../media/layout/contact/contact-collage-item-01.jpg';
import MediaCollageItem02 from '../../media/layout/contact/contact-collage-item-02.jpg';

import MediaLocationDC from '../../media/layout/contact/location-dc.jpg';
import MediaLocationLisbon from '../../media/layout/contact/location-lisbon.jpg';

import AdaptarFunders from '../../media/layout/adaptar/barra-adaptar.png';
import ContactFormSection from './_contact-form';

const mediaCollageAttrs = (props) => ({
  grid: props.grid || {
    smallUp: ['full-start', 'full-end'],
    mediumUp: ['full-start', 'full-end'],
    largeUp: ['full-start', 'full-end']
  }
});
const MediaCollage = styled(UniversalGridder).attrs(mediaCollageAttrs)`
  grid-column: 1 / -1;

  &::before {
    ${stripe};
    z-index: 2;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    grid-row: 1;
    grid-column-start: content-3;
    grid-column-end: span full-end;
    top: 50%;
    transform: translate(0, calc(-50% + 2rem));

    ${media.smallUp`
      grid-column-start: content-2;
      transform: translate(0, calc(-50% + 6rem));
    `}

    ${media.mediumUp`
      top: 100%;
      transform: translate(0, calc(-100% + 2rem));
      grid-column-start: content-3;
    `}

    ${media.largeUp`
      transform: translate(0, calc(-100% + 4rem));
      grid-column-start: content-4;
    `}

    ${media.xlargeUp`
      transform: translate(0, calc(-100% + 4rem));
      grid-column-start: content-5;
    `}
  }
`;

const mediaCollageListAttrs = (props) => ({
  as: 'ul',
  grid: props.grid || {
    smallUp: ['full-start', 'full-end'],
    mediumUp: ['full-start', 'full-end'],
    largeUp: ['full-start', 'full-end']
  }
});
const MediaCollageList = styled(UniversalGridder).attrs(mediaCollageListAttrs)`
  grid-row: 1;
  grid-row-gap: ${glsp(themeVal('layout.gap.xsmall'))};
  align-items: center;

  ${media.smallUp`
    grid-row-gap: ${glsp(themeVal('layout.gap.small'))};
  `}

  ${media.mediumUp`
    grid-row-gap: ${glsp(themeVal('layout.gap.medium'))};
  `}

  ${media.largeUp`
    grid-gap: ${glsp(themeVal('layout.gap.large'))};
  `}

  ${media.xlargeUp`
    grid-gap: ${glsp(themeVal('layout.gap.xlarge'))};
  `}

  figure {
    max-width: 100%;
    height: auto;

    img {
      min-width: auto;
    }
  }

  li:nth-child(1) {
    position: relative;
    z-index: 1;
    grid-row: 1;
    grid-column: full-start / content-4;

    ${media.smallUp`
      grid-column: content-start / content-4;
    `}

    ${media.mediumUp`
      grid-column: content-start / content-5;
    `}

    ${media.largeUp`
      grid-column: content-2 / content-7;
    `}
  }

  li:nth-child(2) {
    grid-row: 2;
    grid-column: content-2 / content-end;

    ${media.smallUp`
      grid-column: content-3 / full-end;
    `}

    ${media.mediumUp`
      grid-row: 1;
      grid-column: content-5 / content-8;
    `}

    ${media.largeUp`
      grid-column: content-7 / content-11;
    `}
  }
`;

const locationButtonInfoAttrs = () => ({
  forwardedAs: 'a',
  variation: 'primary-text',
  size: 'medium'
});
const LocationButtonInfo = styled(Button).attrs(locationButtonInfoAttrs)`
  margin-left: ${glsp(-0.75)};
`;

const SupportBlock = styled.div`
  a {
    display: flex;
    flex-flow: column;
    gap: ${glsp()};
    background: ${themeVal('color.surface')};
    padding: ${glsp()};
    color: ${themeVal('color.base')};

    &,
    &:not([class]),
    &:visited {
      color: inherit;
    }
  }

  h6 {
    font-size: 1rem;
    line-height: 1.25rem;
  }
`;

const offices = [
  {
    media: {
      src: MediaLocationDC,
      alt: 'Washington, DC',
      attribution: {
        name: 'mrrrddd',
        url: 'https://www.flickr.com/photos/mrdemin/47567601911/'
      }
    },
    title: 'Washington, DC',
    children: (
      <React.Fragment>
        <p>
          1226 9th Street NW
          <br /> Second Floor
          <br /> Washington, DC 20001
          <br /> <strong>United States</strong>
        </p>
        <LocationButtonInfo
          href='https://www.openstreetmap.org/node/3697994134'
          title='View on OpenStreetMap'
        >
          Get directions
        </LocationButtonInfo>
      </React.Fragment>
    )
  },
  {
    media: {
      src: MediaLocationLisbon,
      alt: 'Lisbon',
      attribution: {
        name: 'Luca Sartoni',
        url: 'https://www.flickr.com/photos/lucasartoni/47480231301/'
      }
    },
    title: 'Lisbon',
    children: (
      <React.Fragment>
        <p>
          Travessa da Pereira, 16A
          <br /> Armazém 12A
          <br /> 1170-313 Lisboa
          <br /> <strong>Portugal</strong>
        </p>
        <LocationButtonInfo
          href='https://www.openstreetmap.org/node/8215441973'
          title='View on OpenStreetMap'
        >
          Get directions
        </LocationButtonInfo>
        <SupportBlock>
          <Link to='/adaptar' title='Read more'>
            <h6>With the support from Progama Adaptar</h6>
            <img src={AdaptarFunders} alt='Ad' />
          </Link>
        </SupportBlock>
      </React.Fragment>
    )
  }
];

const Contact = ({ location }) => {
  return (
    <Layout location={location} title='Contact'>
      <Inpage as='section'>
        <InpageHeader leadLayout='column'>
          <InpageHeaderInner>
            <InpageHeadline>
              <InpageTitle>
                Want to work on the world&apos;s hardest geospatial issues with
                us?
              </InpageTitle>
            </InpageHeadline>
            <InpageLead>
              <p>
                At Development Seed, we want to work with ambitious people and
                organizations on projects that improve outcomes for humanity and
                the planet.
              </p>
              <p>
                Our partners want tools that deliver streaming insights about
                the planet and populations, delivered when, where, and how
                decisions are made.
              </p>
            </InpageLead>
          </InpageHeaderInner>
        </InpageHeader>
        <InpageBody isSoftPadded>
          <MediaCollage>
            <MediaCollageList>
              <li>
                <Media
                  decoration='none'
                  src={MediaCollageItem01}
                  alt='Collage item'
                />
              </li>
              <li>
                <Media
                  decoration='none'
                  src={MediaCollageItem02}
                  alt='Collage item'
                />
              </li>
            </MediaCollageList>
          </MediaCollage>

          <ContactFormSection />

          <Fold>
            <FoldInner>
              <FoldHeader>
                <FoldSuptitle>Around the world</FoldSuptitle>
              </FoldHeader>
              <FoldBody>
                <FoldBlock>
                  <FoldBlockTitle>
                    Our
                    <br />
                    offices
                  </FoldBlockTitle>
                  <FoldMosaicFeatures items={offices} />
                </FoldBlock>
              </FoldBody>
            </FoldInner>
          </Fold>
        </InpageBody>
      </Inpage>
    </Layout>
  );
};

export default Contact;

Contact.propTypes = {
  location: PropTypes.object
};
