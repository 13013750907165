import styled from 'styled-components';
import { Heading } from '@devseed-ui/typography';

import { themeVal, glsp, media } from '@devseed-ui/theme-provider';

import UniversalGridder from './universal-gridder';
import { leading } from './typography/leading';
import { SupHeading } from './typography/supheading';
import { line } from './motifs';

export const Fold = styled(UniversalGridder).attrs({ as: 'section' })`
  position: relative;
  z-index: 1;
`;

export const FoldInner = styled(UniversalGridder).attrs({
  as: 'div',
  grid: {
    smallUp: ['full-start', 'full-end'],
    mediumUp: ['content-start', 'full-end'],
    largeUp: ['content-start', 'full-end']
  }
})`
  position: relative;
  z-index: 1;
  grid-row: 1;
  padding: ${glsp(2, 0)};
  background: ${themeVal('color.base-50a')};
  grid-row-gap: ${glsp(2)};

  ${media.smallUp`
    padding: ${glsp(3, 0)};
  `}

  ${media.mediumUp`
    padding: ${glsp(6, 0)};
    grid-row-gap: ${glsp(4)};
  `}

  ${media.largeUp`
    padding: ${glsp(8, 0)};
    grid-row-gap: ${glsp(6)};
  `}

  ${media.xlargeUp`
    grid-row-gap: ${glsp(8)};
  `}
`;

export const FoldHeader = styled.header`
  grid-column: content-start / content-end;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: ${glsp()};

  ${media.mediumUp`
    grid-column: content-2 / content-end;
    grid-gap: ${glsp(2)};
  `}

  ${media.largeUp`
    grid-column: content-2 / span 7;
  `}

  ${media.xlargeUp`
    grid-column: content-2 / span 6;
  `}
`;

const foldTitleAttrs = (props) => ({
  as: props.as || 'h1'
});
export const FoldTitle = styled(Heading).attrs(foldTitleAttrs)`
  font-size: 2rem;
  line-height: 2.5rem;
  margin: 0;

  ${media.mediumUp`
    font-size: 3rem;
    line-height: 3.5rem;
  `}

  ${media.largeUp`
    font-size: 4rem;
    line-height: 4.5rem;
  `}
`;

export const FoldSuptitle = styled(SupHeading).attrs({
  as: 'h2',
  variation: 'primary'
})`
  grid-row: 1;
`;

export const FoldLead = styled.div`
  ${leading()}

  > *:not(:last-child) {
    margin-bottom: ${glsp(1)};
  }
`;

export const FoldBody = styled(UniversalGridder).attrs({
  as: 'div',
  grid: {
    smallUp: ['full-start', 'full-end'],
    mediumUp: ['content-start', 'full-end'],
    largeUp: ['content-2', 'full-end']
  }
})``;

export const FoldBlock = styled(UniversalGridder).attrs({
  as: 'section',
  grid: {
    smallUp: ['content-start', 'full-end'],
    mediumUp: ['content-start', 'full-end'],
    largeUp: ['content-2', 'full-end']
  }
})`
  grid-row-gap: ${glsp(2)};

  ${media.mediumUp`
    grid-row-gap: ${glsp(3)};
  `}

  ${media.largeUp`
    grid-row-gap: ${glsp(4)};
  `}
`;

export const FoldBlockTitle = styled(Heading).attrs((props) => ({
  as: props.as ?? 'h1'
}))`
  position: relative;
  z-index: 2;
  font-size: 2rem;
  line-height: 2.5rem;
  grid-column-start: content-start;
  grid-column-end: span 2;
  margin: 0;

  ${media.mediumUp`
    font-size: 3rem;
    line-height: 3.5rem;
    grid-column-start: content-3;
    grid-column-end: span 4;
  `}

  ${media.largeUp`
    font-size: 4rem;
    line-height: 4.5rem;
    grid-column-end: span 4;
  `}

  &::after {
    ${line};
    position: absolute;
    left: calc(100% + ${glsp(themeVal('layout.gap.xsmall'))});
    bottom: 1rem;
    width: calc(100% + 100vw);

    ${media.smallUp`
      left: calc(100% + ${glsp(themeVal('layout.gap.small'))});
    `}

    ${media.mediumUp`
      left: calc(100% + ${glsp(themeVal('layout.gap.medium'))});
      bottom: 1.5rem;
    `}

    ${media.largeUp`
      left: calc(100% + ${glsp(themeVal('layout.gap.large'))});
      bottom: 2rem;
    `}

    ${media.xlargeUp`
      left: calc(100% + ${glsp(themeVal('layout.gap.xlarge'))});
    `}
  }
`;
