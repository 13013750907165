import React, { useEffect } from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import { media, glsp } from '@devseed-ui/theme-provider';
import { Button } from '@devseed-ui/button';
import { Heading } from '@devseed-ui/typography';
import {
  Form,
  FormGroup,
  FormGroupHeader,
  FormLabel,
  FormGroupBody,
  FormInput,
  FormHelper,
  FormHelperMessage,
  FormTextarea
} from '@devseed-ui/form';

import { Section, SectionHeader, SectionHeadline } from '../../styles/section';

const CollaborateSectionHeadline = styled(SectionHeadline)`
  ${media.largeUp`
    grid-column: content-2 / content-10;
  `}

  ${media.xlargeUp`
    grid-column: content-2 / content-10;
  `}
`;

const ContactForm = styled(Form)`
  grid-column: content-start / content-end;

  ${media.smallUp`
    grid-column: content-start / content-4;
  `}

  ${media.mediumUp`
    grid-gap: ${glsp(2)};
    grid-column: content-2 / content-8;
  `}

  ${media.largeUp`
    grid-column: content-3 / content-11;
  `}
`;

const CollaborateMessage = styled(Heading).attrs({ as: 'p' })`
  line-height: 1.75;
  ${media.mediumUp`
    font-size: 2rem;
  `}
  ${media.largeUp`
    font-size:  2.25rem;
    line-height: 3.5rem;
  `}
`;

const FormActions = styled.div`
  display: flex;
  align-items: center;
  gap: ${glsp()};
  margin-left: auto;
`;

async function sendEmail(params) {
  const response = await fetch(
    'https://3wzsvivmqe.execute-api.us-east-1.amazonaws.com/prod/dsoContactForm',
    {
      method: 'POST',
      // Keep text/plain to avoid preflight request.
      headers: {
        'Content-Type': 'text/plain'
      },
      body: JSON.stringify(params)
    }
  );

  if (response.status >= 400) {
    throw new Error(`Error sending email: ${response.status}`);
  }
}

export default function ContactFormSection() {
  const {
    register,
    handleSubmit,
    reset,
    setError,
    formState: { isSubmitting, isSubmitSuccessful, errors }
  } = useForm();

  useEffect(() => {
    if (isSubmitSuccessful) {
      const tid = setTimeout(() => reset({ keepIsSubmitted: false }), 3000);
      return () => clearTimeout(tid);
    }
  }, [reset, isSubmitSuccessful]);

  const onSubmit = async (data) => {
    try {
      await sendEmail(data);
      reset();
    } catch (error) {
      setError('root.server', {
        type: 'manual',
        message: error.message
      });
    }
  };

  return (
    <Section>
      <SectionHeader>
        <CollaborateSectionHeadline>
          <CollaborateMessage>
            Drop us a line at{' '}
            <a
              href='mailto:info@developmentseed.org'
              title="Contact Deelopment Seed's email"
            >
              info@developmentseed.org
            </a>
            .<br />
            View opening positions by{' '}
            <Link to='/careers' title='View open job offers'>
              exploring Careers
            </Link>
            .<br />
            Come visit us at any of our offices.
            <br />
            Or if you have something else on your mind get in touch below.
          </CollaborateMessage>
        </CollaborateSectionHeadline>
      </SectionHeader>
      <ContactForm onSubmit={handleSubmit(onSubmit)}>
        <FormGroup>
          <FormGroupHeader>
            <FormLabel htmlFor='contact-name'>Name</FormLabel>
          </FormGroupHeader>
          <FormGroupBody>
            <FormInput
              type='text'
              size='large'
              id='contact-name'
              disabled={isSubmitting}
              invalid={!!errors.name}
              {...register('name', { required: true })}
            />
            <FormHelper>
              <div>
                {!!errors.name && (
                  <FormHelperMessage invalid>
                    Let us know what we should call you.
                  </FormHelperMessage>
                )}
              </div>
            </FormHelper>
          </FormGroupBody>
        </FormGroup>

        <FormGroup>
          <FormGroupHeader>
            <FormLabel htmlFor='contact-email'>Email</FormLabel>
          </FormGroupHeader>
          <FormGroupBody>
            <FormInput
              type='text'
              size='large'
              id='contact-email'
              disabled={isSubmitting}
              invalid={!!errors.email}
              {...register('email', {
                required: true,
                pattern: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/
              })}
            />
            <FormHelper>
              <div>
                {!!errors.email && (
                  <FormHelperMessage invalid>
                    It is important that we are able to get back to you.
                  </FormHelperMessage>
                )}
              </div>
            </FormHelper>
          </FormGroupBody>
        </FormGroup>

        <FormGroup>
          <FormGroupHeader>
            <FormLabel htmlFor='contact-message'>
              What&apos;s on your mind?
            </FormLabel>
          </FormGroupHeader>
          <FormGroupBody>
            <FormTextarea
              size='large'
              id='contact-message'
              placeholder='Describe what you need and how we can help.'
              disabled={isSubmitting}
              invalid={!!errors.message}
              {...register('message', { required: true })}
            />
            <FormHelper>
              {!!errors.message && (
                <FormHelperMessage invalid>
                  We&apos;re sure you have something to say. We&apos;ll start:{' '}
                  <i>Hello!</i>
                </FormHelperMessage>
              )}
            </FormHelper>
          </FormGroupBody>
        </FormGroup>

        <FormActions>
          {isSubmitting && <p>Your message is being sent!</p>}
          {isSubmitSuccessful && (
            <p>We got your message and will get back to you!</p>
          )}
          {errors.root?.server && (
            <p>
              Oh no! Something went wrong sending your message. Please try
              again.
            </p>
          )}
          <Button type='submit' variation='primary-fill'>
            Send
          </Button>
        </FormActions>
      </ContactForm>
    </Section>
  );
}
